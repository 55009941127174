export default {
	getAll() {},
	getAllBrandByClient() {},
	getAllServicesByBrand() {},
	getAllAreaByService() {},
	getAllSubareaByArea() {},
	getAllLocationPatient() {},
	getAllTypeService() {},
	save(id, data) {},
	saveMaintenanceService(id, data) {},
	delete(id) {},
	update(id, data) {},
	movePatient(id) {},
	altaInfo(id) {},
	altaFisico(id) {},
	altaCancel(id) {},
}
