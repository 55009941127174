import PatientsRepositoryInterface from '../repositories/interfaces/PatientsRepositoryInterface'
import MedicinesRepositoryInterface from '../repositories/interfaces/MedicinesRepositoryInterface'
import ExamsRepositoryInterface from '../repositories/interfaces/ExamsRepositoryInterface'
import SpecialtiesRepositoryInterface from '../repositories/interfaces/SpecialtiesRepositoryInterface'
import DependenciesRepositoryInterface from '../repositories/interfaces/DependenciesRepositoryInterface'
import EpsRepositoryInterface from '../repositories/interfaces/EpsRepositoryInterface'
import RisksRepositoryInterface from '../repositories/interfaces/RisksRepositoryInterface'
import PatientBedRepositoryInterface from '../repositories/interfaces/PatientBedRepositoryInterface'
import EmployeesRepositoryInterface from '../repositories/interfaces/EmployeesRepositoryInterface'
import AssignExamPatientRepositoryInterface from '../repositories/interfaces/AssignExamPatientRepositoryInterface'
import AssignMedicinePatientRepositoryInterface from '../repositories/interfaces/AssignMedicinePatientRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	patientsRepository: bind('PatientsRepository', PatientsRepositoryInterface),
	medicinesRepository: bind('MedicinesRepository', MedicinesRepositoryInterface),
	examsRepository: bind('ExamsRepository', ExamsRepositoryInterface),
	specialtiesRepository: bind('SpecialtiesRepository', SpecialtiesRepositoryInterface),
	dependenciesRepository: bind('DependenciesRepository', DependenciesRepositoryInterface),
	epsRepository: bind('EpsRepository', EpsRepositoryInterface),
	risksRepository: bind('RisksRepository', RisksRepositoryInterface),
	patientBedRepository: bind('PatientBedRepository', PatientBedRepositoryInterface),
	employeesRepository: bind('EmployeesRepository', EmployeesRepositoryInterface),
	assignExamPatientRepository: bind('AssignExamPatientRepository', AssignExamPatientRepositoryInterface),
	assignMedicinePatientRepository: bind('AssignMedicinePatientRepository', AssignMedicinePatientRepositoryInterface),
}
