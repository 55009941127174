<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  import RepositoryProvider from './providers/RepositoryProvider'
  import GlobalRepositoryProvider from '@/providers/RepositoryProvider'

  export default {
    provide: { ...RepositoryProvider, ...GlobalRepositoryProvider },
  }
</script>

<style lang="scss" scoped>
</style>
